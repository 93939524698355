import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Vyrábíme polyethylenové (PE) sáčky a pytle" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "výroba-polyethylenových-pe-sáčků-a-pytlů"
    }}>{`Výroba polyethylenových (PE) sáčků a pytlů`}</h1>
    <h2 {...{
      "id": "základní-rozměry-a-síla"
    }}>{`Základní rozměry a síla`}</h2>
    <p>{`Pro materiál polyethylen si můžete zvolit tyto základní rozměry:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`minimálně`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`maximálně`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`výška`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`100 mm`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`750 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`šířka`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`90 mm`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1000 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`síla`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`30 µm`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`120 µm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`kusů`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1000 ks`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`20 000 ks (1.)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`(^1) Větší počet kusů je samozřejmě možný a v takovém případě nás prosím `}<a parentName="p" {...{
        "href": "/kontakt#miroslav-kukla"
      }}>{`kontaktujte`}</a>{`, rádi vám připravíme `}<strong parentName="p">{`nabídku na míru`}</strong>{`.`}</p>
    <h2 {...{
      "id": "volitelné-parametry-úpravy-pe-sáčků"
    }}>{`Volitelné parametry (úpravy) PE sáčků`}</h2>
    <p>{`Kromě základních (povinných) parametrů si můžete zvolit tyto další parametry pro polyethylenový sáček nebo pytel:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Parametr`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Ukázka`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "/technologie/parametry/derovani/"
            }}>{`Děrování`}</a>{` (max. 2 díry)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "124px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141.1290322580645%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAAsSAAALEgHS3X78AAABYklEQVQ4y+3V+0+CQADAcf//v6L1Ws1X1MpHaa+poC3SpWGtmsoBB8c9ZDRFsO50bi0ds/mr7Dt2O/hw99OR+N7gSjBKoWXZEK7ZZDKZTqdhGAq8s7t/fXtfvCpflq7jK9/cpbPSo/rEGFNVVeCUdOYHEcTMoV5MNhlSf9R5+6gpdYxxtVoVOJmVEPV6wBqYdkx9A1oOUZ9flEaDECLL8mzlrISHPn8MIIpJtxwbs2ZbU+oc4y3e4i1eD+sr2XwQjw3o8FYuK+ZX4vTJKce6CTWDvBrY+MtQz0JtQPsWsjFtdrr1xgM/DBRFEfgolXG9r4EJ24B1AAHQ0Wdb1RcbfjeQqnufJseMnyQ1WcHYrVQqAu8dHOaLpfNcYVFxqcJFLs8H/LXjZKbZavm+r2ldgRFyBzrQgcEbzO7L/Z4fjcecRVEkMKHUQRi5a+Ugl2N+bgdBIDD/RvifuORsfk9s8rv5AT4e34XOE8JjAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/b640257dfcce0ee10bfc434e6096e699/4a469/sacek_derovani.webp 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/b640257dfcce0ee10bfc434e6096e699/d6e54/sacek_derovani.png 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/b640257dfcce0ee10bfc434e6096e699/d6e54/sacek_derovani.png",
                  "alt": "Sáček - ukázka děrování",
                  "title": "Sáček - ukázka děrování",
                  "loading": "lazy",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
      `}</picture>{`
    `}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "/technologie/parametry/klopa/"
            }}>{`Klopa`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "124px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141.1290322580645%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAAsSAAALEgHS3X78AAAByUlEQVQ4y+2VaW/aMBiA8/9/Q6ETaqeKikOqWgahgzYJjK50wNAayGUnDomPhISrrTiyRJq0b6HQfdyrR/7kR7L9HuaiDwT3IflKGvA1vlZv1Opf98DXr5vfZusImRBjnMiVoWNZlgqQBtNQIYImkmRE3qJhvyeI4nK54hqKR5mHsGeTNBDxMPW6gNG3SJF/ZbKnNZ7n7lUv8JhLGKFpYMp8xvqQktcIaKogSv3BgCv/JEOTPRnsB0jjCbABZPwzxi+RoSrXN5WxonCnxerQWXzXSNdgKTzqtI/C8l0XWAgYxtn5eRCGXKFQXCxXLiZ7jk1oEM4kqaXrGoTw5CSzXq+5fLHszVemQxBmKVguJdPZndgGAI7H40w2u9vtuHyh5M9XlkNszFJALqWJ3EIIdTqdXC6X5PlQeTKZCIJQKpWOkNuWafZ6vc7Dw8HyfXJnMBqNVFU9Uo4fTNf142VN0/7LR8iXxbI/e7csteOu+Juqi8vCdPES1/2eSYIZDeZNsWUYhizLf4okk/3E3zZvvvCVaj2dKt/4fJF3nGTm2badyI7jGgACaO4l3mYhO+7E7XYbr4nsByHzp+8nljabTewn8ubA+Gc/xm/d8Nz26F7WGQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/596a24522ad682c9dc9d226072b86fa3/4a469/sacek_klopa.webp 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/596a24522ad682c9dc9d226072b86fa3/d6e54/sacek_klopa.png 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/596a24522ad682c9dc9d226072b86fa3/d6e54/sacek_klopa.png",
                  "alt": "Sáček - ukázka klopy",
                  "title": "Sáček - ukázka klopy",
                  "loading": "lazy",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
      `}</picture>{`
    `}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "/technologie/parametry/klopa#zkoseni-klopy/"
            }}>{`Zkosení klopy`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "124px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141.1290322580645%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAAsSAAALEgHS3X78AAAB4klEQVQ4y+2VW2/aMBSA89P3I6Y9bFovUlkBtZnWTi0IEppkHYKVjgQY0zJywYnj2LmsCUolIHTOyMukQmn2OutTnvz52MfHJ8zqofhgVmm6XC52hM79S653hqY5VSfGT20bqmbohnmtqEF8PzUM3/cz+cXeByecAehaiGwBOBj5d6/OPwPoyP1+u91OkoR5ecTKToJcDJBnuY8DXA9hMnKig4+ih+C38Xhvf59vtZjqKXsNErowIR7ejIuJpEc1XoK2NZlMOp1OX5YZlmXHOOG+Y8X0bg3v62PIpiep5AuIRFEClq2q6sVlTdN0pnLChnHS0/CN7vU2c6MRFES8IFmWpao/jsvlOJ5lchAnjovxU9smYcRdrWX14PBwPp/nkacO3pStPGcolyGEykA5KpWyq1rLNGG2623B+iM3r0SMsSAI5UqliEwI4Tju7Oz82TLdNkKo2+32erdFZHrmwWBgmmYR2YZwOBwCAApG/i8Xlqun74OdZV74ROXRaJTLx9WTcHZP697G/hbo2yB3s0ZLtG1bUZS8SF6/edvkhYta47Le3E690Sq9q9Da1nWdPo9MtmxI26JhTp+ETqNtZLWizTql30z2w19eEO4OdeaLxTJNM3n5zEGdddhM/oe/zcNv76neldTAgtYAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/240bff77e12cd50fa663645855fa6566/4a469/sacek_zkoseni.webp 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/240bff77e12cd50fa663645855fa6566/d6e54/sacek_zkoseni.png 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/240bff77e12cd50fa663645855fa6566/d6e54/sacek_zkoseni.png",
                  "alt": "Sáček - ukázka zkosení klopy",
                  "title": "Sáček - ukázka zkosení klopy",
                  "loading": "lazy",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
      `}</picture>{`
    `}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "/technologie/parametry/lepici-paska/"
            }}>{`Lepící páska`}</a>{` (opakovatelná nebo trvalá)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "124px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141.1290322580645%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAAsSAAALEgHS3X78AAAB50lEQVQ4y+3V30/aUBQH8P7/Szb3MBe2LCM4HjYtQnWzFqryqy2UMlwgMrEYB63a370ttz/Auguy7GGmHbjHnXwfbtJ8ck5um1Ps/gmFPQlDz5vMCybGdSdhGEZRFATBbDZDB+x9Jjv8IfW+D84GYkz6A7HbH+SJfQghz/PT6XTe+dnzl9eqKaumpBjy4vBn0CPFdHrnYjqTDQIfx/EDknRdF0t/yHZ6fU3TFUVR40qrcvwXknIAYFlub/+zbhhYLk+0u+fHvTE31FlReyxq49I4/nbJn3bpk7JpmoIgHFIUmh/Ddwtj1dqsDDcZ+TUjPZpXjJyuD0fXClU6Qp0rlUqJptGdYZ/wXcuFaGYtoXTpVieLtAchQRDIzy8MYQNMrmRldKPFRn3Age9vb+9Uq7Vf2IFoJOlWS4iik1QJ4czWltBur4wPEA6CFxsbo/F4nc6e56VSbwAAK+PDIu06DnpPvu+vgwGwWZZdfp5rYIZh/uP18MednGa5V9IN8klRFxj8xrnCHgzvFMPWLCchtls8OrFtu16vL3Hq7bum8LVcY6oMF59yjc3lC2gVtlqtJe50TrlGo9FsxqfJ8wzLXlyIyEwgRKtzjoHjGLZt/kUMy0ILGJkwnC7sPRasUg/TRnfRP/hj/ATK6d8PnQ1R8AAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/bed16f6d48ebdc7a1a621293819b1c07/4a469/sacek_lepici.webp 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/bed16f6d48ebdc7a1a621293819b1c07/d6e54/sacek_lepici.png 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/bed16f6d48ebdc7a1a621293819b1c07/d6e54/sacek_lepici.png",
                  "alt": "Sáček - ukázka lepicí pásky",
                  "title": "Sáček - ukázka lepicí pásky",
                  "loading": "lazy",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
      `}</picture>{`
    `}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "/technologie/parametry/recyklacni-znaky/"
            }}>{`Recyklační znaky`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "158px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "36.708860759493675%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsSAAALEgHS3X78AAABaElEQVQY0yVRvcrCQBBMYWmTRlAEsfMBkncQ8wZ2IqS0sRHRKp2QTrQxhRaGJKCglaDCB2Ih0UoMimhQxIAp4l+aqPlGb+Hu9mZ3dnfuqM/n4/v+YDBYr9dwyJXs9/u93W57nuf/7PF4LJdLwzB2u93lcgFCYa1Wq8lk0ul0CATm+/1GRqPRiEajpmkCvN1uuq67rluv1wuFAqoggbper6qqwtM0bT6fkyan0wngeDwOhUKCIADZbDb9fj+RSFA/q1ariFLD4RAHAmg+Go1s2369Xs1ms1arOY6Tz+czmQzIi8UiFovF4/F0Ok3T9Ha7bbVaVK/X+/sZWimKcj6fwcFguVwOgzAMk0qlQJZlORgMIvp8PiHBsqxkMvnVPJvNut0uNGN4XCGsXC5ns9npdMrzvCiKANEqHA5LkgSZlUolEolAzpcMzuFwQCrqEc2YuVgs7vf74/FIHoz8CMuyHMcFAoFSqQTkH7BxL+J2N0qxAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/cb0335b27e1316ebd5f70d1cee407701/81b7c/recyklace.webp 158w"],
                  "sizes": "(max-width: 158px) 100vw, 158px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/cb0335b27e1316ebd5f70d1cee407701/c26ae/recyklace.png 158w"],
                  "sizes": "(max-width: 158px) 100vw, 158px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/cb0335b27e1316ebd5f70d1cee407701/c26ae/recyklace.png",
                  "alt": "Sáček - ukázka recyklační znaky",
                  "title": "Sáček - ukázka recyklační znaky",
                  "loading": "lazy",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
      `}</picture>{`
    `}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><del parentName="td"><a parentName="del" {...{
                "href": "/technologie/parametry/prolozene-dno/"
              }}>{`Proložené dno`}</a></del>{` (2.)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "124px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141.1290322580645%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAAsSAAALEgHS3X78AAABqklEQVQ4y9WVW0/iQBSA5/+/7LvGROODGlFAdrMRKBWlpcHLxpZWLoVouczQ2k7H2hYKLHsI++CLDYUH48mXyTycb26ZnIPm8/nftWOVvFgsViNabBHo6PiE468uC8V8kYunwJUyFzmMMWiUunAKtLd/2CevDf251TViaHZenvvkd57TNA1kWZbH4zFKpbPMHw9Gr9hyYhiats3eBanWbDZBFkUxDEN0kjqnXtDDJvgx9Ill0bcbUWo0GiDzPO/7wYYyVyr5vv8Vcrlc9jwvsbx6MEEQGGMJZUFqtVog12o113UTy+12G+T7hweH0mTytSDpuj6bzeCT2La9oayqqmmaG8r1en0LeZudv/ux044XGNjsQ8bn9IhlfpAVRVnKp6kz5vkDPMIjK4YhMW3KKoL4f2dNW8q753nVim5f6H2PxXBnuDIJszd/9E4HCpBYrRJC0I+dg1/l23ShkikKsVRyvLR3nDEMg1Kayf3sdLvoSVWVx8e6LK+DqiiTyWQ6ndqOEwQhclxmU3ddHBcuDEV3OMSwBJpEUSJWrQPKyLJub9AooNNEUQSTf1zb3I+m0vqwAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/d6185a3781c40e8d4d409b7413f21f7d/4a469/sacek_prolozene.webp 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/d6185a3781c40e8d4d409b7413f21f7d/d6e54/sacek_prolozene.png 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/d6185a3781c40e8d4d409b7413f21f7d/d6e54/sacek_prolozene.png",
                  "alt": "Sáček - ukázka proloženého dna",
                  "title": "Sáček - ukázka proloženého dna",
                  "loading": "lazy",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
      `}</picture>{`
    `}</span></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><del parentName="td"><a parentName="del" {...{
                "href": "/technologie/parametry/raminko/"
              }}>{`Ramínko`}</a></del>{` (3.)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "124px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141.1290322580645%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAAsSAAALEgHS3X78AAAB50lEQVQ4y+3V30/aUBQH8P7/Szb3MBe2LCM4HjYtQnWzFqryqy2UMlwgMrEYB63a370ttz/Auguy7GGmHbjHnXwfbtJ8ck5um1Ps/gmFPQlDz5vMCybGdSdhGEZRFATBbDZDB+x9Jjv8IfW+D84GYkz6A7HbH+SJfQghz/PT6XTe+dnzl9eqKaumpBjy4vBn0CPFdHrnYjqTDQIfx/EDknRdF0t/yHZ6fU3TFUVR40qrcvwXknIAYFlub/+zbhhYLk+0u+fHvTE31FlReyxq49I4/nbJn3bpk7JpmoIgHFIUmh/Ddwtj1dqsDDcZ+TUjPZpXjJyuD0fXClU6Qp0rlUqJptGdYZ/wXcuFaGYtoXTpVieLtAchQRDIzy8MYQNMrmRldKPFRn3Age9vb+9Uq7Vf2IFoJOlWS4iik1QJ4czWltBur4wPEA6CFxsbo/F4nc6e56VSbwAAK+PDIu06DnpPvu+vgwGwWZZdfp5rYIZh/uP18MednGa5V9IN8klRFxj8xrnCHgzvFMPWLCchtls8OrFtu16vL3Hq7bum8LVcY6oMF59yjc3lC2gVtlqtJe50TrlGo9FsxqfJ8wzLXlyIyEwgRKtzjoHjGLZt/kUMy0ILGJkwnC7sPRasUg/TRnfRP/hj/ATK6d8PnQ1R8AAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/bed16f6d48ebdc7a1a621293819b1c07/4a469/sacek_lepici.webp 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/bed16f6d48ebdc7a1a621293819b1c07/d6e54/sacek_lepici.png 124w"],
                  "sizes": "(max-width: 124px) 100vw, 124px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/bed16f6d48ebdc7a1a621293819b1c07/d6e54/sacek_lepici.png",
                  "alt": "Sáček - ukázka lepicí pásky",
                  "title": "Sáček - ukázka lepicí pásky",
                  "loading": "lazy",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
      `}</picture>{`
    `}</span></td>
        </tr>
      </tbody>
    </table>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Parametr `}<strong parentName="p">{`Proložené dno`}</strong>{` je možné zvolit jen pro `}<a parentName="p" {...{
            "href": "/vyroba/polypropylenove-pp-sacky-a-pytle/"
          }}>{`polypropylenové sáčky`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Parametr `}<strong parentName="p">{`Ramínko`}</strong>{` dno je možné zvolit jen pro `}<a parentName="p" {...{
            "href": "/vyroba/polypropylenove-pp-sacky-a-pytle/"
          }}>{`polypropylenové sáčky`}</a></p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      